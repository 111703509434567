<!-- System: STA
    Purpose: This compoment will display all 
            archived companies companies where user
            can restore company.
-->

<template>
  <v-col cols="12" sm="12" md="3">
    <!-- Company Card -->
    <v-card class="mx-auto my-12 company-boxes" max-width="300" elevation="10">
      <!-- Company Image -->
      <img
        :src="imageCompany(company)"
        height="200"
        width="200"
        class="img-fluid proj-img mt-2"
        @error="
          $event.target.src = require('../../assets/images/dashboard_icons/no_image.svg')
        "
      />
      <!-- Company Name -->
      <h3 class="text-center">
        {{
          company.name.length < 30
            ? company.name
            : company.name.substring(0, 30) + "...."
        }}
      </h3>
      <!-- Company Details -->
      <v-card-text>
        <p class="font-weight-medium mt-n2">
          Projects :
          <span>{{ company.projects_count }}</span>
        </p>
        <p
          class="font-weight-medium mt-n3"
          v-if="company.user_status != 'employee'"
        >
          Members :
          <span>{{ company.employees_count }}</span>
        </p>
      </v-card-text>
      <!-- Company Action -->
      <v-card-actions class="d-flex justify-center py-3 mt-n9">
        <!-- Restore Button -->
        <v-btn color="primary" text @click="restoreCompany(company)">
          <v-icon>mdi-restore</v-icon>
          Restore
        </v-btn>
      </v-card-actions>
    </v-card>
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </v-col>
</template>


<script>
import { baseURL } from "@/assets/js/urls";
import Snackbar from "@/components/includes/Snackbar";
export default {
  components: {
    Snackbar,
  },
  name: "Organization",
  props: ["company"],
  data: () => ({
    snackbar: false,
    snackbarColor: "",
    message: "",
  }),
  computed: {
    url() {
      return baseURL.API_URL;
    },
  },
  mounted() {},
  methods: {
    /**
     * This function is resposible for
     * returning the image of company
     */
    imageCompany(company) {
      if (company.logo != null) {
        return this.url + "storage/" + company.logo;
      } else {
        return require("../../assets/images/no_image.svg");
      }
    },
    /**
     * This function is resposible for
     * restore company
     */
    restoreCompany(company) {
      this.$store
        .dispatch("custom/restore_company", { company_id: company.id })
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.$store.commit("custom/splice_archived_company", company.id);
            this.$store.commit("custom/push_company", company);
            (this.snackbar = true),
              (this.message = "Your record is restore successfully!"),
              (this.snackbarColor = "green");
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
          this.snackbar = true;
          this.message = "Error Occoured!";
          this.snackbarColor = "red";
        });
    },
  },
};
</script>

<style scoped>
.company-boxes {
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px -20px rgba(0, 0, 0, 0.1);
}
</style>
